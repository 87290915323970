import { isDevMode } from '@angular/core';
import { ErrorBase } from '@core/models/error.model';

// Dinh nghia cac loi client
export class LoginNormalError extends Error {
  constructor(mgs: string) {
    super(mgs);
  }
}

export class LoginByTokenError extends Error {
  constructor(mgs: string) {
    super(mgs);
  }
}

export class LoginByToken3ThError extends Error {
  constructor(mgs: string) {
    super(mgs);
  }
}

export class AuthErrorModel {
  public static handle(error: ErrorBase) {
    throw new LoginNormalError(error.desc?.message);
  }

  public static handleErrorLoginByToken(error: ErrorBase) {
    throw new LoginByTokenError(error.desc?.message);
  }

  public static handleLoginByToken3ThError(error: ErrorBase) {
    throw new LoginByToken3ThError(error.desc?.message);
  }
}
