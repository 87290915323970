import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DecentralierService } from '@features/auth/core/auth-action/decentralization.services';
import { map, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ACCOUNT_TYPE_QUERY_PARAMS } from '../../guards/route-query-params.variable';
import { E_ACCOUNT_TYPE, URI_APIs } from '@features/auth/const';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { StorageHelper } from '@features/auth/shared/helpers/storage.helper';
import {
  IAuthentication,
  IAuthenticationVersion2,
  ILoginErrorBehavior,
} from '@features/auth/core/domain/login/models/authentication.interface';
import {
  ILoginResponse,
  ILoginByTokenResponse,
  ILoginUserData,
} from './dto/login-response.interface';
import { AuthErrorModel } from './models/version-2/auth-error-version2.model';
import { AuthModel } from './models/version-2/auth.model-verssion2';

@Injectable()
export class AuthService
  implements IAuthentication, IAuthenticationVersion2, ILoginErrorBehavior
{
  private readonly httpClient = inject(HttpClient);
  private readonly detranalizerService = inject(DecentralierService);
  private readonly conf = inject(CoreAuthConfigService);

  authEvent: Subject<any> = new Subject<any>();
  authEvent$: Observable<any> = this.authEvent.asObservable();

  private authModel!: AuthModel;

  constructor() {
    this.authModel = new AuthModel();
  }
  resetAppKey(): void {
    StorageHelper.setAppKey(this.conf.applicationConfig.appKey ?? '');
  }

  login(
    username: string,
    password: string,
    typeAccount: string
  ): Observable<AuthModel> {
    if (username?.trim().length == 0 || password.trim().length == 0) {
      throw new Error(`Username and password should not be empty`);
    }

    this.resetAppKey()
    const url = this.conf.buildURL(URI_APIs.LOGIN);

    const requestBody: any = {
      username: username,
      password: password,
    };
    if (typeAccount) {
      requestBody.loai_tai_khoan = typeAccount;
    }
    const response = this.httpClient.post<ILoginResponse>(url, requestBody);

    return response.pipe(
      catchError((err) => {
        this.countLoginError();
        this.authEvent.next({ key: 'LOGIN_ERROR', value: err });
        return throwError(() => AuthErrorModel.handle(err));
      }),
      map((response: any) => {
        return this.authModel.mapDataFromAPI(response);
      }),

      // lưu token và thông tin user phục vụ việc lấy dữ liệu từ server và hiển thị thông tin user
      tap((formated) => {
        this.authEvent.next({ key: 'LOGIN', value: formated });
        this.setAuthResponseToStorage(
          formated.token ?? '',
          formated.result,
          formated.listmenu_active
        );
        this.clearLoginCountError();

      })
    );
  }

  getAccountType(accountType?: string) {
    switch (accountType) {
      case ACCOUNT_TYPE_QUERY_PARAMS.SINH_VIEN:
        return E_ACCOUNT_TYPE.SINH_VIEN;
      case ACCOUNT_TYPE_QUERY_PARAMS.NHAN_VIEN:
        return E_ACCOUNT_TYPE.NHAN_VIEN;
      default:
        throw new Error(`Account type not in [NHAN_VIEN, SINH_VIEN]`);
    }
  }
  // ex http://localhost:4200/?redirect=dashboard&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE5MzciLCJOaGFuVmllbklEIjoiTk5WMDA1MDU4OCIsImFwcCI6Ik1PQklMRV9IVVRFQ0giLCJpcCI6Ijo6ZmZmZjoxMjcuMC4wLjEiLCJzZXJ2aWNlX2lkIjoiMTkzNyIsImlhdCI6MTcwMjUzMzk4ODU3MCwidHlwZSI6IkxPR0lOX1RPS0VOIiwiZXhwIjoxNzAyNTMzOTg4NTk4fQ.n8U3CupiWvsQHg98N00Smgtb0QqR6YlIvM5Gdw8AscA
  handleAuthenticateByToken(token: string, accountType?: string) {
    this.checkValidToken(token);
    accountType = this.getAccountType(accountType);

    const endpoint = this.conf.buildURL(URI_APIs.LOGIN_BY_TOKEN);
    const requestBody: any = {
      token: token,
    };

    if (accountType) {
      requestBody.loai_tai_khoan = accountType;
    }

    return this.httpClient
      .post<ILoginResponse>(endpoint, requestBody)
      .pipe(
        catchError((err) => {
          this.authEvent.next({ key: 'LOGIN_BY_TOKEN_ERROR', value: err });
          return throwError(() => AuthErrorModel.handleErrorLoginByToken(err));
        }),
        map((response) => {
          return this.authModel.mapDataFromAPI(response);
        }),
        tap((response) => {
          console.log(response)
          this.authEvent.next({ key: 'LOGIN_BY_TOKEN', value: response });
          this.setAuthResponseToStorage(
            response.token,
            response.result,
            response.listmenu_active
          );
        })
      );
  }

  handleAuthenticateByTokenThirdParty(token: string, accountType?: string) {
    this.checkValidToken(token);
    accountType = this.getAccountType(accountType);
    const requestBody: any = {
      token: token,
    };

    if (accountType) {
      requestBody.loai_tai_khoan = accountType;
    }
    const endpoint = this.conf.buildURL(URI_APIs.LOGIN_BY_THIRD_PARTY);
    return this.httpClient
      .post<ILoginByTokenResponse>(endpoint, requestBody)
      .pipe(
        catchError((err) => {
          this.authEvent.next({
            key: 'LOGIN_BY_TOKEN_3TH_ERROR',
            value: err,
          });
          return throwError(() =>
            AuthErrorModel.handleLoginByToken3ThError(err)
          );
        }),
        map((response) => {
          return this.authModel.fromLoginByToken3thResponse(response);
        }),

        tap((response) => {
          this.authEvent.next({
            key: 'LOGIN_BY_TOKEN_3TH',
            value: response,
          });
          this.setAuthResponseToStorage(
            response.token,
            response.result,
            response.listmenu_active
          );
        })
      );
  }

  logout() {
    return this.httpClient.post(this.conf.buildURL(URI_APIs.LOGOUT), {}).pipe(
      tap((value) => {
        this.authEvent.next({ key: 'LOGOUT', value: true });
        // Delay to optimize ux
        setTimeout(() => {
          this.clearLocalStorageItem();
        }, 300);
      })
    );
  }

  checkValidToken(token: string) {
    if (token?.length == 0) {
      throw new Error(`Token should not be empty!`);
    }
  }

  setAuthResponseToStorage(
    token: string,
    authInfo: ILoginUserData | any | undefined,
    listmenu: string[] | number[]
  ) {
    StorageHelper.setAuthInfo(token, authInfo, listmenu);
  }

  clearLocalStorageItem = () => {
    StorageHelper.emptySessionAndLocalStorage();
    this.detranalizerService.setElements([]); // <--- Clear active element in state;
  };

  countLoginError() {
    let countLoginError = +this.getLoginCountError();
    if (countLoginError) {
      countLoginError += 1;
    } else {
      countLoginError = 1;
    }
    StorageHelper.storeLoginCountError(countLoginError);
    return countLoginError;
  }

  getLoginCountError() {
    return +StorageHelper.getLoginCountError();
  }

  clearLoginCountError() {
    StorageHelper.storeLoginCountError(0);
  }

  isLoggedIn = (): boolean => {
    // return new JWTHelpler().idValid(this.getAccessToken());
    const token = this.getAccessToken();
    return token !== undefined && token?.length !== 0;
  };

  getAccessToken = () => {
    return StorageHelper.getAccessToken();
  };

  buildTokenByJWTPrefix = (type: string = 'JWT') => {
    return `${type} ${this.getAccessToken()}`;
  };

  register(info: any): Observable<any> {
    return of(false);
  }
}
