export interface UserInfo {
    code: number
    messager: string
    NhanVienID: string
    NhanVienGuid: string
    contact_id: string
    Ho_Ten: string
    loai: string
    email: string
    khoa_viet_tat: string
    image: string
}

export class AuthModelVersion1 {
    token: string
    token_eduzaa: any
    app_key: string
    is_xac_thuc_email: number
    is_da_doi_mat_khau: number
    is_da_xac_thuc_so_dien_thoai: number
    result: UserInfo
    listmenu_active: any[]

    constructor(args?: any) {
        this.token = args?.token;
        this.token_eduzaa = args?.token_eduzaa;
        this.app_key = args?.app_key;
        this.is_xac_thuc_email = args?.is_xac_thuc_email;
        this.is_da_doi_mat_khau = args?.is_da_doi_mat_khau;
        this.is_da_xac_thuc_so_dien_thoai = args?.is_da_xac_thuc_so_dien_thoai;
        this.result = args?.result;
        this.listmenu_active = args?.listmenu_active;
    }
    mapDataFromAPI(args?: any) {
        return this;
    }

    // fromLoginByToken3thResponse(args: ILoginByTokenResponse) {
    //     this.token = args.token;
    //     this.contactID = args.contact_id;
    //     this.username = args.username;
    //     this.result = args.data;
    //     this.listmenu_active = [];
    //     return this;
    // }
}
