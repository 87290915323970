import {
  ILoginUserData,
  ILoginResponse,
  ILoginByTokenResponse,
  ILoginByTokenData,
  ILoginByGoogleResponse,
} from '../../dto/login-response.interface';

export class AuthModel {
  listmenu_active: string[] = [];
  result!: ILoginUserData | ILoginByTokenData | any;
  token!: string | undefined | any;
  contactID!: string | undefined;
  username!: string | undefined;

  mapDataFromAPI(args: ILoginResponse) {
    this.token = args?.token;
    this.contactID = args?.contact_id;
    this.username = args?.username;
    this.result = args?.data;
    this.listmenu_active = args?.data?.menu?.map((id) => id.toString());
    return this;
  }

  fromLoginByToken3thResponse(args: ILoginByTokenResponse) {
    this.token = args.token;
    this.contactID = args.contact_id;
    this.username = args.username;
    this.result = args.data;
    this.listmenu_active = [];
    return this;
  }

  fromLoginByGmailResponse(args: ILoginByGoogleResponse) {
    this.token = args.token;
    this.result = {
      avatar: args.userInfo.avatar,
      email: args.userInfo.email ?? '',
      ho_ten: args.userInfo.ho_ten ?? '',
    };
    this.listmenu_active = args.menu?.map((e) => e.toString());
    return this;
  }
}
