import { IErrorMetadata } from "../../dto/login-response.interface";

export class AuthVersion1Error extends Error {
  isSuccess!: boolean;
  isFailure!: boolean;
  error!: IErrorMetadata;

  constructor(args?: any) {
    super();
    this.isSuccess = args.isSuccess;
    this.isFailure = args.isFailure;
    this.error = args.error;
    this.message = this.error.message;
  }
}