import { Injectable, inject } from '@angular/core';
import {
  IAuthentication,
  IAuthenticationVersion1,
} from './models/authentication.interface';
import { Observable, map, mergeMap, of, tap, throwError } from 'rxjs';
import { JWTHelpler } from '@shared/helpers/jwt/jwt.helper';
import { StorageHelper } from '@features/auth/shared/helpers/storage.helper';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { URI_APIS_VERSION_1 } from '@features/auth/const';
import { HttpClient } from '@angular/common/http';
import { AuthModelVersion1 } from './models/version-1/auth-model-version1';
import { AuthVersion1Error } from './models/version-1/auth-error-version1.model';

@Injectable()
export class AuthVersion1Service
  implements IAuthentication, IAuthenticationVersion1
{
  private readonly httpClient = inject(HttpClient);
  private readonly conf = inject(CoreAuthConfigService);

  login(username: string, password: string): Observable<any> {
    if (username?.trim().length == 0 || password.trim().length == 0) {
      throw new Error(`Username and password should not be empty`);
    }
    this.resetAppKey()
    const url = this.conf.buildURL(URI_APIS_VERSION_1.LOGIN);

    const requestBody = {
      username: username,
      password: password,
    };

    return this.httpClient.post(url, requestBody).pipe(
      mergeMap((response: any) => {
        if (response?.isSuccess === false && response.isFailure === true) {
          return throwError(() => new AuthVersion1Error(response));
        }
        return of(response);
      }),
      map((response: any) => new AuthModelVersion1(response)),
      tap((formated) => {
        StorageHelper.setAuthInfo(
          formated.token,
          formated.result,
          formated.listmenu_active
        );

      })
    );
  }

  resetAppKey(): void {
    StorageHelper.setAppKey(this.conf.applicationConfig.appKey ?? '');
  }
  logout(): Observable<any> {
    StorageHelper.emptySessionAndLocalStorage();
    return of(true);
  }

  isLoggedIn(): boolean {
    return new JWTHelpler().idValid(StorageHelper.getAccessToken());
  }

  getAccessToken(): string {
    return StorageHelper.getAccessToken();
  }

  buildTokenByJWTPrefix(type: string): string {
    return `${type} ${this.getAccessToken()}`;
  }

  // TOTO: Implement handle login by token
  handleAuthenticateByToken(token: string): Observable<any> {
    StorageHelper.setAccessToken(token);
    const url = this.conf.buildURL(URI_APIS_VERSION_1.LOGIN_BY_TOKEN);
    return this.httpClient.post(url, {}).pipe(
      map((response) => new AuthModelVersion1(response)),
      tap((formated) => {
        StorageHelper.setAuthInfo(
          formated.token,
          formated.result,
          formated.listmenu_active
        );
      })
    );
  }
}
